// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-big-idea-js": () => import("./../src/templates/big-idea.js" /* webpackChunkName: "component---src-templates-big-idea-js" */),
  "component---src-templates-answer-js": () => import("./../src/templates/answer.js" /* webpackChunkName: "component---src-templates-answer-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */)
}

